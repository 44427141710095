import React, { Component, Fragment } from "react";
import Container from "react-bootstrap/Container";
import tvIcon from "../static/images/tv.svg";
import graficIcon from "../static/images/grafic.svg";
import Button from "@material-ui/core/Button";
import BouncingArrow from "./BouncingArrow";

import "../styles/slide12.scss";

const RECEIPT_TEXT = {
  characteristic_0: {
    "-1": "angepasstes",
    "0": "kritisches",
    "1": "rebellisches",
    null: "",
  },
  characteristic_1: {
    "-1": "konsumgeile",
    "0": "durchschnittlich verbrauchende",
    "1": "äußerst sparsame",
    null: "ganz normale",
  },
  characteristic_2: {
    "-1": "auf dich bezogene",
    "0": "solidarische",
    "1": "selbstlose",
    null: "ganz normale",
  },
  characteristic_3: {
    "-1": (
      <Fragment>
        Du verhältst dich <span className="font-bold">ausbeuterisch</span>.
      </Fragment>
    ),
    "0": (
      <Fragment>
        Du verhältst dich <span className="font-bold">ausbeuterisch</span>,
        teilweise wirst du jedoch <span className="font-bold">ausgebeutet</span>
        .
      </Fragment>
    ),
    "1": (
      <Fragment>
        Du wirst jedoch <span className="font-bold">ausgebeutet</span>.
      </Fragment>
    ),
    null: "",
  },
  characteristic_4: {
    "-1": (
      <Fragment>
        Im Großen und Ganzen bist du{" "}
        <span className="font-bold">unzufrieden</span>.<br />
      </Fragment>
    ),
    "0": (
      <Fragment>
        Im Großen und Ganzen bist du{" "}
        <span className="font-bold">weder zufrieden noch unzufrieden</span>.
        <br />
      </Fragment>
    ),
    "1": (
      <Fragment>
        Im Großen und Ganzen bist du{" "}
        <span className="font-bold">zufrieden</span>.<br />
      </Fragment>
    ),
    null: "",
  },
  filmsText: {
    typeA: (
      <Fragment>
        Du lebst im <span className="font-bold">Hier und Jetzt</span> und weißt
        stets, was im <span className="font-bold">Trend</span> liegt.
        Unbekümmert, manchmal provokant, immer cool - dein{" "}
        <span className="font-bold">spontaner Konsumstil</span> zeichnet dich
        aus. Auf <span className="font-bold">Spaß</span> und Unterhaltung willst
        du nicht verzichten.
      </Fragment>
    ),
    typeB: (
      <Fragment>
        Du bist <span className="font-bold">leistungsbereit</span> und dir dabei
        stets deiner <span className="font-bold">Verantwortung</span> bewusst.
        Du bist immer
        <span className="font-bold"> ganz vorne</span> mit dabei. Dein Anspruch
        ist Teil deiner DNA. Du bekommst, was du willst. Beim Konsum achtest Du 
        auf Stil und Qualität. <span className="font-bold">Exklusivität</span>{" "}
        ist dir besonders wichtig.
      </Fragment>
    ),
    typeC: (
      <Fragment>
        Du bist <span className="font-bold">gut informiert</span> und beschäftigst 
        Dich mit den Umständen, in denen wir leben. Wenn du konsumierst, dann{" "}
        <span className="font-bold">umwelt- und gesundheitsbewusst</span>. Für
        ein authentisch nachhaltiges Teil greifst du auch mal tiefer in die Tasche 
         - denn für dich gilt:{" "} <span className="font-bold">Weniger ist mehr</span>.
      </Fragment>
    ),
    typeD: (
      <Fragment>
        Leistungsbereitschaft und{" "}
        <span className="font-bold">Anpassungsfähigkeit</span> zeichnen dich
        aus. Auch wenn du manchmal Bedenken hast, wie alles weitergehen soll -
        du strebst ein <span className="font-bold">harmonisches</span> Leben in
        der Mitte der Gesellschaft an. Konsumentscheidungen triffst du meistens {" "}
        <span className="font-bold">pragmatisch</span>, du shoppst gern modern,
        aber immer auch praktisch.
      </Fragment>
    ),
  },
  filmsNumber: {
    typeA: 1,
    typeB: 2,
    typeC: 3,
    typeD: 4,
  },
  filmsVideo: {
    typeA: "https://www.youtube-nocookie.com/embed/r2OdLt3BYJI",
    typeB: "https://www.youtube-nocookie.com/embed/2H9rF099hRY",
    typeC: "https://www.youtube-nocookie.com/embed/87uHDsYZeEs",
    typeD: "https://www.youtube-nocookie.com/embed/c80E6pTXZwQ",
  },
};

class Slide12 extends Component {
  state = {
    isUserAcceptVideo: this.userAccept?.isUserAcceptVideo || false,
  };

  componentDidMount() {
    document.addEventListener("keydown", this.setSlide);
    console.log(this.state);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.setSlide);
  }

  get userAccept() {
    return JSON.parse(localStorage.getItem("decision"));
  }

  onVideoClick = (type) => {
    const { setReceiptVideo, setSlide } = this.props;
    setReceiptVideo(RECEIPT_TEXT.filmsVideo[type]);
    setSlide(12);
  }

  get normalizedCharacteristics() {
    const { characteristics } = this.props;
    return Object.values(characteristics).map((characteristic) => {
      if (!characteristic) return 0;
      return characteristic > 0 ? 1 : -1;
    });
  }

  setSlide = (e) => {
    if (e.keyCode === 13) this.props.setSlide(0);
  };

  get receipt() {
    const data = {
      exponatesAmount: 2,
      price: 1000,
      results: this.normalizedCharacteristics,
    };
    const type = this.handleType(data);
    const { results, price, exponatesAmount } = data;
    const { isUserAcceptVideo } = this.state;

    return (
      <div className="receipt">
        <img src={graficIcon} className="grafic-icon" alt="" /> <br />
        Du hast für <span className="font-bold">{price} Egos</span> mit{" "}
        <span className="font-bold">{exponatesAmount} Exponaten</span>{" "}
        gechattet. Dein Verhalten wurde dabei analysiert: Du hast ein{" "}
        <span className="font-bold">
          {RECEIPT_TEXT.characteristic_0[String(results[0])]}
        </span>{" "}
        kapitalistisches Ich. Dabei bist du eine{" "}
        <span className="font-bold">
          {RECEIPT_TEXT.characteristic_1[String(results[1])]}
        </span>
        ,{" "}
        <span className="font-bold">
          {" "}
          {RECEIPT_TEXT.characteristic_2[String(results[2])]}{" "}
        </span>{" "}
        Person. <br />
        {RECEIPT_TEXT.filmsText[type]} <br />
        {RECEIPT_TEXT.characteristic_3[String(results[3])]}
        {RECEIPT_TEXT.characteristic_4[String(results[4])]}
        Auf dich wartet nun ein {" "}
        <span className="font-bold">360° Film</span>., 
        der auf dein ganz persönliches Spielergebnis zugeschnitten ist. <br />
        <div className="font-size-48 font-bold">
          Dein Film ist Film {RECEIPT_TEXT.filmsNumber[type]}.
        </div>
        <div className="video-details">
          <img src={tvIcon} className="tv-icon" alt="" />
          <div className="slide-buttons">
            <Button
              onClick={() => this.onVideoClick(type)} 
              disabled={!isUserAcceptVideo}
            >
              Film ansehen
            </Button>
          </div>
        </div>
      </div>
    );
  }

  handleType = (data) => {
    const types = {
      typeA: 0,
      typeB: 0,
      typeC: 0,
      typeD: 0,
    };

    const { results } = data;

    if (results[0] === -1) {
      types.typeD++;
      types.typeA++;
    }
    if (results[0] === 0) {
      types.typeB++;
    }
    if (results[0] === 1) {
      types.typeC++;
    }
    // ---------------------
    if (results[1] === -1) {
      types.typeA++;
    }
    if (results[1] === 0) {
      types.typeB++;
      types.typeD++;
    }
    if (results[1] === 1) {
      types.typeC++;
    }
    // ---------------------
    if (results[2] === -1) {
      types.typeA++;
      types.typeB++;
    }
    if (results[2] === 0) {
      types.typeC++;
      types.typeD++;
    }
    // ---------------------
    if (results[3] === -1) {
      types.typeB++;
    }
    if (results[3] === 0) {
      types.typeC++;
      types.typeD++;
    }
    if (results[3] === 1) {
      types.typeA++;
    }
    // ---------------------
    if (results[4] === -1) {
      types.typeA++;
    }
    if (results[4] === 0) {
      types.typeC++;
    }
    if (results[4] === 1) {
      types.typeD++;
      types.typeB++;
    }

    const max = Math.max(...Object.keys(types).map((key) => types[key]));

    return Object.keys(types).find((key) => types[key] === max);
  };

  render() {
    const { setSlide } = this.props;
    return (
      <Container>
        <div className="slide-12 slides">
          <h2>Quittung</h2>
          {this.receipt}
          <div onClick={() => setSlide(0)}>
            <BouncingArrow text="Zurück" />
          </div>
        </div>
      </Container>
    );
  }
}

export default Slide12;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "./styles/ImpressumDetailsModal.scss"

export default function ImpressumDetailsModal({ show, onHide }) {
  return (
    <div>
      <Dialog
        open={show}
        onClose={onHide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="impressumDetailsModal"
      >
        <DialogTitle id="scroll-dialog-title" className="dialog-title">
          <IconButton aria-label="close" onClick={onHide}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span className="underline">Impressum: </span>
            <br />
            <br />
            Herausgeberin der Web-Version des „Kapitalismus Games“:
            <br />
            <div
              className="image-block"
            >
              <div>
                <strong>
                  Bundeszentrale für politische Bildung/bpb © 2020
                </strong>
                <br />
                Adenauerallee 86
                <br />
                53113 Bonn
              </div>
              <img
                src={require("../../static/images/bpb.jpeg")}
                height="45px"
                alt="bpb.jpeg"
              />
            </div>
            <br />
            Dr. Sabine Dengel (verantwortlich)
            <br />
            Lisa Philippen-Burdich
            <br />
            <br />
            <div
                           className="image-block"

            >
              <div>
                Konzept und technische Umsetzung:
                <br />
                <strong>Playersjourney UG</strong>
              </div>
              <img
                src={require("../../static/images/players-journey.png")}
                height="45px"
                alt="bpb.jpeg"
              />
            </div>
            <br />
            Die Web-Version des „Kapitalismus Games“ ist Teil der Kooperation
            zwischen der{" "}
            <strong>
              Kunst- und Ausstellungshalle der Bundesrepublik Deutschland, Bonn
            </strong>{" "}
            und der <strong>Bundeszentrale für politische Bildung/bpb </strong>
            im Bildungsprogramm zur Ausstellung „Wir Kapitalisten. Von Anfang
            bis Turbo“
            <br />(
            <a
              href="https://www.bundeskunsthalle.de/wirkapitalisten"
              target="_blank"
            >
              www.bundeskunsthalle.de/wirkapitalisten
            </a>
            ).
            <br />
            <br />
            <br />
            <span className="underline">
              {" "}
              Quellen- und Bildnachweise:
            </span>
            <br />
            <br />
            Mit freundlicher Genehmigung der Kunst- und Ausstellungshalle der
            Bundesrepublik Deutschland, Bonn:
            <ul>
              <li>
                Porzellanmanufaktur Frankenthal, <i>Kaufmannsfrau</i>, 1762/63,
                Kunstgewerbemuseum – Staatliche Museen zu Berlin
                <br />
                Walter Schulze-Mittendorf, <i>Maschinenmensch</i>, Abguss (1994)
                eines Nachbaus des Künstlers (1972), Musée de la Cinémathèque
                française, Paris
              </li>
              <li>
                Duane Hanson, <i>Two Workers</i>, 1993, Axel Thünker (Foto),
                Stiftung Haus der Geschichte der Bundesrepublik Deutschland,
                Bonn © VG Bild-Kunst, Bonn 2020
              </li>
              <li>
                <i>Karl Marx</i>, Public domain
              </li>
              <li>
                Mathias Böhler & Christian Orendt, <i>Give us, Dear,</i> 2013,
                eine Kooperation des Neuen Museums Nürnberg mit Elke Antonia
                Schloter und Volker Koch
              </li>
              <li>
                <i>Steingeld, Yap, Karolinen,</i> Föderierte Staaten von
                Mikronesien, um 1900, Übersee-Museum, Bremen
              </li>
              <li>
                Evelyn de Morgan, <i>Die Verehrung des Mammon</i>, 1909, De
                Morgan Foundation, Guildford
              </li>
              <li>
                Alexandre Cabanel,{" "}
                <i>Porträt von Olivia Peyton Murray Cutting</i>, 1887, Museum of
                the City of New York
              </li>
              <li>
                Julian Röder,<i> Available for Sale</i>, 2007, © Julian Röder,
                courtesy Galerie Russi Klenner
              </li>
              <li>
                <i>Angela Merkels Mobiltelefon</i>, Siemens S55, 2003, Stiftung
                Haus der Geschichte der Bundesrepublik Deutschland, Bonn
              </li>
              <li>
                Rolf Scholz,{" "}
                <i>Der Fall Daidalos und Ikarus (Otto Lilienthal)</i>, 1985,
                Senatsverwaltung für Kultur und Europa, Berlin
              </li>
            </ul>
            <br />
            <strong>
              {" "}
              <i>
                Die Poster für das Kapitalismus Game verwenden die oben
                genannten Bilder,
              </i>{" "}
            </strong>
            <br />
            Text/Design der Plakat-Grafiken: Playersjourney UG/Studio Mahr
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
